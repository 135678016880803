// Import styles
import 'video.js/dist/video-js.css';
import '../scss/main.scss';
// Import js
import Game from './Game';
import {isMobileTablet} from './utils';

const GAME_SELECTOR = '.game';

class Main {
	constructor() {
		// initialise global config - important to run before anything else
		window.supervid = window.supervid || {};
		window.supervid.__config = window.supervid.__config || {};
		window.supervid.__game = window.supervid.__game || [];

		// properties
		this.config = window.supervid.__config;
		this.game = window.supervid.__game;

		document.addEventListener('DOMContentLoaded', this.init.bind(this));
		window.addEventListener('resize', this.resize.bind(this));
	}

	init() {
		this.$game = document.querySelector(GAME_SELECTOR);

		if (!this.$game) return; // bail early if no game on the page

		const p = this.setupGame(this.$game);
		if (p) this.game = p;

		if (!this.game) return; // bail early if no valid game

		this.config.isDesktop = !isMobileTablet();
		if (this.config.isDesktop) {
			document.body.classList.add('is-desktop');
		}
		this.game.isDesktop = this.config.isDesktop;

		this.resize();

		// initialize game
		this.game.bind();

		// Add gtm
		if (window.CLIENT_NAME) {
			let gtmScript = document.createElement('script');
			gtmScript.innerHTML =
				"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TMT4SWL');";
			document.head.append(gtmScript);
		}
	}

	setupGame($el) {
		try {
			let p = new Game($el);
			return p;
		} catch (e) {
			console.error('Could not initialize game.', e, $el);
			return false;
		}
	}

	resize() {
		// Fix 100vh for ios mobile
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
}

new Main();
