import {Howl} from 'howler';
import EventClass from './lib/EventClass';
import {ensurePromise} from './utils';

export default class Sound extends EventClass {
	constructor(options) {
		super();
		this.options = options;
	}

	bind() {
		this.createPlayer();
	}

	unbind() {
		this.destroyPlayer();
	}

	createPlayer() {
		this.player = new Howl(this.options);

		// bind Howler events
		this.player.on('play', () => this.emit('play'));
		this.player.on('mute', () => this.emit('mute'));
		this.player.on('load', () => this.emit('load'));
	}

	destroyPlayer() {
		this?.player.unload();
	}

	play() {
		return ensurePromise(() => this?.player.play());
	}

	pause() {
		return ensurePromise(() => this?.player.pause());
	}

	stop() {
		return ensurePromise(() => this?.player.stop());
	}

	mute(muted) {
		this?.player.mute(muted);
	}

	/**
	 * Get the sound duration
	 * @returns {number} the duration in seconds
	 */
	duration() {
		return this?.player.duration() ?? 0;
	}

	/**
	 * Get or set the current time (in seconds)
	 * @param {number} seconds The time to seek to in seconds
	 */
	currentTime(seconds) {
		if (seconds !== undefined) {
			this?.player.seek(seconds);
			return;
		}
		return this?.player.seek() ?? 0;
	}

	/**
	 * Fade sound
	 * @param {number} from - volume (between 0.0 and 1.0)
	 * @param {number} to - volume (between 0.0 and 1.0)
	 * @param {number} duration - time in milliseconds
	 */
	fade(from, to, duration) {
		this?.player.fade(from, to, duration);
	}

	/**
	 * Get/set volume
	 * @param {number} volume (from 0.0 to 1.0)
	 */
	volume(volume) {
		if (volume !== undefined) {
			this?.player.volume(volume);
			return;
		}
		return this?.player.volume() ?? 1;
	}

	state() {
		return this?.player.state();
	}

	playing() {
		return this?.player.playing();
	}
}
