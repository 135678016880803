import gsap from 'gsap';
import {Howler} from 'howler';
import audioButton from '../assets/audios/button-click.mp3';
import audioGameError from '../assets/audios/game-error.mp3';
import audioGameSuccess from '../assets/audios/game-success.mp3';
import audioLoopEnd from '../assets/audios/loop-end.mp3';
import audioLoopIntro from '../assets/audios/loop-intro.mp3';
import audioGame from '../assets/audios/voice.mp3';
import {gameFaces, gamePattern, gameScreens, originalVideoSpecs} from './data';
import Sound from './Sound';
import {isValueBetween} from './utils';
import Video from './Video.js';

window.CLIENT_NAME = '';
const LOADING_TEXT = 'Loading...';

// Videos
const CONFIG_VIDEO_GAME = {
	id: '25e55b5367bcec0f251761752530f7cc',
	selector: 'super-game',
	options: {
		controls: false,
		autoplay: false,
		loop: false,
		preload: true,
		muted: false,
		playsinline: true,
		controlBar: {
			pictureInPictureToggle: false,
		},
		html5: {
			vhs: {
				bandwidth: 8000000,
				limitRenditionByPlayerDimensions: false,
			},
		},
	},
};

const CONFIG_VIDEO_BG = {
	id: 'c057670bc6f65b958ad83bf5fc0c67a6',
	selector: 'super-background',
	options: {
		controls: false,
		autoplay: false,
		loop: true,
		preload: true,
		muted: true,
		playsinline: true,
		controlBar: {
			pictureInPictureToggle: false,
		},
	},
};

// Audios
const CONFIG_AUDIO_DEFAULTS = {
	autoplay: false,
	loop: false,
	mute: false,
	html5: true,
};
const CONFIG_AUDIO_CLIENT = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [require('../assets/audios/client-name.mp3').default],
};
const CONFIG_AUDIO_GAME = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioGame],
	mute: true,
};
const CONFIG_AUDIO_LOOP_INTRO = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioLoopIntro],
	// volume: 0.5,
	loop: true,
};
const CONFIG_AUDIO_LOOP_END = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioLoopEnd],
	// volume: 0.5,
	loop: true,
};
const CONFIG_AUDIO_BUTTON = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioButton],
	// volume: 0.3,
};
const CONFIG_AUDIO_GAME_SUCCESS = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioGameSuccess],
	// volume: 0.3,
};
const CONFIG_AUDIO_GAME_ERROR = {
	...CONFIG_AUDIO_DEFAULTS,
	src: [audioGameError],
	// volume: 0.3,
};

const MOLE_POINTS = 500;
const FACE_POINTS = -500;
const MISS_MOLE_POINTS = -100;

const SOUND_CLIENT_START_TIME = 26.3;

const COLOR_WHITE = '#fff';
const COLOR_BLUE_LIGHT = '#2a307e';
const COLOR_RED = '#d04e34';

const SPLASH_BUTTON_SELECTOR = '.splash__button';
const HAMMER_SELECTOR = '.hammer';
const HAMMER_IMAGES_SELECTOR = '.hammer__image-wrapper';
const HAMMER_IMG_PINK_SELECTOR = '.hammer__image--pink';
const HAMMER_TARGET_SELECTOR = '.hammer__target';
const LYRICS_ITEMS_SELECTOR = '.lyrics__item';
const LYRICS_CLIENT_SELECTOR = '.lyrics__item--client';
const HOLE_SCORE_SELECTOR = '.hole-score';
const INSTRUCTIONS_SCREEN_SELECTOR = '.instructions';
const SCORE_SCREEN_SELECTOR = '.score';
const SCORE_TITLE_SELECTOR = '.score__title';
const SCORE_POINTS_SELECTOR = '.score__points';
const SCORE_POINTS_VALUE_SELECTOR = '.score__points__value';
const SCORE_LYRICS_SELECTOR = '.score__lyrics span';
const SCORE_LYRICS_CLIENT_SELECTOR = '.score__lyrics__client';
const SCORE_FOOTER_SELECTOR = '.score__footer';

const BUTTON_PLAY = '.button-play';
const BUTTON_PLAY_AGAIN = '.button-play-again';

/**
 * start sequence:
 * - `new Player($el)` - instanciantes the player
 * - `bind()` - give life to the player (bind events, start video if autoplay,…)
 */
export default class Game {
	constructor($el) {
		// Properties
		this.$el = $el;
		this.hasStarted = false;
		this.gameState = {};
		this.hammer = {};
		this.videos = {};
		this.sounds = {};
		this.buttons = {};
		this.points = {};
		this.lyrics = {};

		// Bindings
		this.handleVideoStarted = this.handleVideoStarted.bind(this);
		this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleHammerPosition = this.handleHammerPosition.bind(this);
		this.handleInstructionsBtnClick = this.handleInstructionsBtnClick.bind(this);
		this.handleScoreBtnClick = this.handleScoreBtnClick.bind(this);
	}

	bind() {
		// Get DOM Elements
		this.hammer.$element = document.querySelector(HAMMER_SELECTOR);
		this.hammer.$image = this.hammer.$element.querySelector(HAMMER_IMAGES_SELECTOR);
		this.hammer.$pink = this.hammer.$element.querySelector(HAMMER_IMG_PINK_SELECTOR);
		this.hammer.$target = this.hammer.$element.querySelector(HAMMER_TARGET_SELECTOR);
		this.lyrics.$items = [...document.querySelectorAll(LYRICS_ITEMS_SELECTOR)];
		this.points.$holeScore = document.querySelector(HOLE_SCORE_SELECTOR);
		this.$splashButton = document.querySelector(SPLASH_BUTTON_SELECTOR);
		this.$scoreScreen = document.querySelector(SCORE_SCREEN_SELECTOR);
		this.$instructionsScreen = document.querySelector(INSTRUCTIONS_SCREEN_SELECTOR);
		this.buttons.$play = document.querySelector(BUTTON_PLAY);
		this.buttons.$playAgain = document.querySelector(BUTTON_PLAY_AGAIN);

		// Prepare Videos
		// background
		const videoBackground = new Video(CONFIG_VIDEO_BG);
		this.videos.background = videoBackground;
		this.videos.background.bind();
		// game
		const videoGame = new Video(CONFIG_VIDEO_GAME);
		this.videos.game = videoGame;
		this.videos.game.bind();

		// Prepare Audios
		Howler.autoSuspend = false;
		// game
		const soundGame = new Sound(CONFIG_AUDIO_GAME);
		this.sounds.game = soundGame;
		this.sounds.game.bind();
		this.sounds.game.isEssential = true;
		// client
		if (window.CLIENT_NAME) {
			const soundClient = new Sound(CONFIG_AUDIO_CLIENT);
			this.sounds.client = soundClient;
			this.sounds.client.bind();
			this.sounds.client.isEssential = true;
		} else {
			this.sounds.client = null;
		}
		// loop intro
		const soundLoopIntro = new Sound(CONFIG_AUDIO_LOOP_INTRO);
		this.sounds.loopIntro = soundLoopIntro;
		this.sounds.loopIntro.bind();
		this.sounds.loopIntro.isEssential = true;
		// loop end
		const soundLoopEnd = new Sound(CONFIG_AUDIO_LOOP_END);
		this.sounds.loopEnd = soundLoopEnd;
		this.sounds.loopEnd.bind();
		this.sounds.loopEnd.isEssential = true;
		// button
		const soundButton = new Sound(CONFIG_AUDIO_BUTTON);
		this.sounds.button = soundButton;
		this.sounds.button.bind();
		// game success
		const soundGameSuccess = new Sound(CONFIG_AUDIO_GAME_SUCCESS);
		this.sounds.gameSuccess = soundGameSuccess;
		this.sounds.gameSuccess.bind();
		// game error
		const soundGameError = new Sound(CONFIG_AUDIO_GAME_ERROR);
		this.sounds.gameError = soundGameError;
		this.sounds.gameError.bind();

		// Setup Elements
		this.points.total = 0;
		this.showSplashScreen();
		this.hammer.animation = this.setupHammerAnimation();
		if (!this.isDesktop) {
			this.hammer.mobileInitPos = {
				x: (3 * window.innerWidth) / 4,
				y: window.innerHeight / 2,
			};
		}

		// Events Listeners
		window.addEventListener('resize', this.handleResize.bind(this));
		document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
	}

	/**
	 * Start Video
	 */
	startVideo() {
		if (!this.videos.game || !this.videos.background) return;

		this.videos.background.play();
		this.videos.game.play();
		this.videos.game.on('timeupdate', this.handleTimeUpdate);
		this.videos.game.on('play', this.handleVideoStarted);

		this.setupLyrics();
	}

	/**
	 * EVENTS HANDLERS
	 */

	/**
	 * Handle Resize - resize event
	 */
	handleResize() {
		if (!this.isDesktop) {
			this.hammer.mobileInitPos = {
				x: (3 * window.innerWidth) / 4,
				y: window.innerHeight / 2,
			};
			gsap.set(this.hammer.$element, {...this.hammer.mobileInitPos});
		}
	}

	/**
	 * Handle visibility change
	 * Pauses sounds/videos when user leave browser (ex: locked screen or went back to the mobile main/home screen)
	 */
	handleVisibilityChange() {
		if (document.visibilityState === 'visible') {
			// Page is visible => PLAY !
			if (!this.currentlyPlaying) return; // Wasn't unvisible before

			// Only play videos/audios that were playing before the page was not visible anymore (to avoid playing all the sounds/videos)
			this.currentlyPlaying.forEach(item => {
				item.play();

				if (item.forcePause) item.forcePause = false;
			});
		} else {
			// Page is not visible => PAUSE !
			this.currentlyPlaying = []; // Store videos/audios that are currently playing before pausing them so we can replay them when the page is visible again

			Object.keys(this.videos).forEach(video => {
				if (!this.videos[video].paused()) {
					this.currentlyPlaying.push(this.videos[video]);

					this.videos[video].forcePause = true; // This is used to avoid triggering a `.play()` just after the video has been paused because of an other fix
					this.videos[video].pause();
				}
			});

			Object.keys(this.sounds).forEach(sound => {
				if (this.sounds[sound] && this.sounds[sound].playing()) {
					this.currentlyPlaying.push(this.sounds[sound]);

					this.sounds[sound].pause();
				}
			});
		}
	}

	/**
	 * Handle Video Started - play event
	 */
	handleVideoStarted() {
		if (this.hasStarted) return;

		this.hasStarted = true;
		document.body.classList.add('video-has-started');
		document.body.classList.remove('video-has-not-started');

		// Setup Game
		this.updateGameState();

		// Listen click on screen
		if (this.isDesktop) document.addEventListener('click', this.handleClick);
		else document.addEventListener('touchstart', this.handleClick);

		this.videos.game.off('play', this.handleVideoStarted);
	}

	/**
	 * Handle Time Update - timeupdate event
	 */
	handleTimeUpdate() {
		// console.log(this.videos.game.currentTime() + '/' + this.videos.game.duration());
		this.updateGameState();

		if (this.gameState.name === 'game') {
			this.updateLyrics();
		}
	}

	/**
	 * Handle Click - click event
	 * @param {event}
	 */
	handleClick(e) {
		const clickTime = this.videos.game.currentTime();
		const clickPosition = e.touches ? [e.touches[0].clientX, e.touches[0].clientY] : [e.clientX, e.clientY];

		if (this.gameState.name === 'game') {
			// If it's mobile
			if (!this.isDesktop) {
				// Move hammer to current clicked position
				this.handleHammerPosition({
					clientX: clickPosition[0],
					clientY: clickPosition[1],
				});

				// Move hammer back to his init position (=> delay = end of the hammer animation)
				gsap.to(this.hammer.$element, {...this.hammer.mobileInitPos, duration: 0.2, delay: 0.5});
			}

			this.hammer.animation.whack.restart();
		}

		if (!this.isClickInsideGame(clickPosition)) return;

		// Inside game
		const clickGamePosition = this.transformScreenToGamePositions(clickPosition);
		this.handleClickGame(clickTime, clickGamePosition);
		// console.log('CLICK', clickTime, clickPosition, clickGamePosition);
	}

	/**
	 * Handle Hammer Position
	 * @param {event} e
	 */
	handleHammerPosition(e) {
		this.hammer.$element.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
	}

	/**
	 * Handle Splash Button Click
	 */
	handleSplashBtnClick() {
		// this.sounds.button.play(); // Play button sound

		// Show loading state on the Splash button
		this.$splashButton.blur();
		this.$splashButton.innerHTML = LOADING_TEXT;
		this.$splashButton.setAttribute('disabled', true);

		// Handle videos loading state
		const videosPromises = Object.keys(this.videos).map(video => {
			return new Promise((resolve, reject) => {
				if (this.videos[video].bufferedPercent() === 0) {
					this.videos[video].load();
					this.videos[video].on('loadeddata', () => {
						console.log(`video ${video} has now loaded data :: `, this.videos[video].bufferedPercent());

						resolve();
					});
				} else {
					console.log(`video ${video} was already loaded`);
					resolve();
				}
			});
		});

		// Handle sounds loading state
		const soundsPromises = Object.keys(this.sounds).reduce((acc, sound) => {
			// Only if it's an essential sound
			if (this.sounds[sound]?.isEssential) {
				acc.push(
					new Promise((resolve, reject) => {
						if (this.sounds[sound] && this.sounds[sound].state() !== 'loaded') {
							this.sounds[sound].on('load', () => {
								console.log(`sound ${sound} is now loaded`);
								resolve();
							});
						} else {
							console.log(`sound ${sound} was already loaded`);
							resolve();
						}
					}),
				);
			}

			return acc;
		}, []);

		// Once everything is loaded => we can move forward and start the video !
		Promise.all([...videosPromises, ...soundsPromises]).then(() => {
			console.log('all videos and sounds are now loaded !');

			this.startVideo();
		});
	}

	/**
	 * Handle Instructions Button Click
	 */
	handleInstructionsBtnClick() {
		// GTM Custom Event
		if (window.CLIENT_NAME) {
			window.dataLayer.push({
				event: 'gamePlay',
			});
		}

		// Hide instructions screen
		gsap.to(this.$instructionsScreen, {
			autoAlpha: 0,
			duration: 0.3,
			delay: gameScreens[1].loopTime[1] - this.videos.game.currentTime(), // Delay depends on what time we are in the video loop
		});

		this.sounds.button.play(); // Play button sound

		this.stopLoop(this.sounds.loopIntro, true);
	}

	/**
	 * Handle Score Button Click
	 */
	handleScoreBtnClick() {
		this.sounds.button.play(); // Play button sound

		this.replayGame();
	}

	/**
	 * CUSTOM EVENTS HANDLERS
	 */

	/**
	 * Handle Click Game
	 * @param {number} time - seconds
	 * @param {array} position
	 */
	handleClickGame(time, position) {
		if (this.gameState.name === 'game') {
			// Mole click
			// Vibrate on mobile
			// navigator?.vibrate(200);

			// Get where the user clicked
			const moleClicked = this.getObjectClicked(gamePattern, time, position);
			if (moleClicked) {
				this.handleMoleClick(moleClicked);
			} else {
				const faceClicked = this.getObjectClicked(gameFaces, time, position);

				if (faceClicked) {
					this.handleFaceClick(faceClicked);
				}
			}
		}
	}

	/**
	 * Handle Mole Click
	 * @param {object} mole data
	 */
	handleMoleClick(mole) {
		const currentMoleIndex = gamePattern.indexOf(mole);

		if (this.lyrics.list[currentMoleIndex].success) return; // The current mole has already been hit so bail early to not add points multiple times

		// Unmute sound for the mole corresponding lyric
		this.unmuteSound(mole.music.time);

		// Update points (update total + show gained points)
		this.updatePoints(MOLE_POINTS, mole);

		// Update lyric status
		this.updateLyricStatus(currentMoleIndex, true);

		// Play success sound
		this.sounds.gameSuccess.play();
	}

	/**
	 * Handle Face Click
	 * @param {object} face data
	 */
	handleFaceClick(face) {
		// Update points (update total + show lost points)
		this.updatePoints(FACE_POINTS, face);

		// Play error sound
		this.sounds.gameError.play();
	}

	/**
	 * FUNCTIONS
	 */

	/**
	 * Show Splash Screen
	 */
	showSplashScreen() {
		// Show screen
		document.body.classList.add('video-has-not-started');

		// Add event listeners
		this.$splashButton.addEventListener('click', this.handleSplashBtnClick.bind(this));
	}

	/**
	 * Show Instructions Screen
	 */
	showInstructionsScreen() {
		// Setup screen content
		gsap.to(this.$instructionsScreen, {autoAlpha: 1, duration: 0.4, ease: 'power1.inOut', delay: 0.3});
	}

	/**
	 * Show Score Screen
	 */
	showScoreScreen() {
		// Setup screen content
		const scoreTitleEl = this.$scoreScreen.querySelector(SCORE_TITLE_SELECTOR);
		const scoreEl = this.$scoreScreen.querySelector(SCORE_POINTS_SELECTOR);
		const scoreValueEl = scoreEl.querySelector(SCORE_POINTS_VALUE_SELECTOR);
		const scoreLyricsEls = this.$scoreScreen.querySelectorAll(SCORE_LYRICS_SELECTOR);
		const scoreLyricsClientEl = this.$scoreScreen.querySelector(SCORE_LYRICS_CLIENT_SELECTOR);
		const scoreFooter = this.$scoreScreen.querySelector(SCORE_FOOTER_SELECTOR);

		scoreTitleEl.innerHTML = scoreTitleEl.innerText
			.split('')
			.reduce((acc, letter) => (acc += `<span>${letter}</span>`), '');
		scoreValueEl.innerHTML = this.points.total < 0 ? 0 : this.points.total; // Don't display negative score

		// Show client name only if there is one
		if (window.CLIENT_NAME) scoreLyricsClientEl.innerHTML = window.CLIENT_NAME;
		else scoreLyricsClientEl.style.display = 'none';

		// Set display timeline
		const tl = gsap.timeline({delay: 0.5});
		tl.set(this.$scoreScreen, {opacity: 1});

		// Display title
		tl.fromTo([...scoreTitleEl.children], {opacity: 0}, {opacity: 1, duration: 0.01, stagger: 0.06}, 0);
		// Display footer
		tl.fromTo(scoreFooter, {opacity: 0}, {opacity: 1, duration: 0.5}, 0);

		// Display points
		tl.fromTo(
			scoreEl,
			{scale: 0},
			{
				scale: 1,
				ease: 'power1.inOut',
				duration: 0.4,
				onComplete: () => {
					// Blink
					this.blink(scoreEl, COLOR_BLUE_LIGHT, COLOR_WHITE);
				},
			},
		);

		// Display animation for lyrics
		this.lyrics.list.forEach((lyric, i) => {
			tl.fromTo(
				scoreLyricsEls[i],
				{opacity: 0},
				{
					opacity: lyric.success ? 1 : 0.4,
					duration: 0.5,
					onStart: () => {
						if (!lyric.success) scoreLyricsEls[i].style.textDecoration = 'line-through';
						else scoreLyricsEls[i].style.textDecoration = '';
					},
				},
				i === 0 ? '-=0.2' : '-=0.38',
			);
		});
	}

	/**
	 * Update Game State
	 */
	updateGameState() {
		let currentState = this.gameState;
		gameScreens.forEach(screen => {
			if (this.videos.game.currentTime() >= screen.time[0] && this.videos.game.currentTime() < screen.time[1]) {
				currentState = {...screen};
			}
		});

		// Game state changed
		if (currentState.name !== this.gameState.name) {
			this.$el.classList.add(`game--state-${currentState.name}`);
			this.$el.classList.remove(`game--state-${this.gameState.name}`);
			this.gameState = currentState;
			console.log(`::: STATE: ${this.gameState.name} :::`, this.videos.game.currentTime());

			this.setupGameScreens();
		}
	}

	/**
	 * Update Lyrics
	 * (Called on video timeupdate like updateGameState)
	 */
	updateLyrics() {
		let currentLyric = this.lyrics.current;
		const soundCurrentTime = this.videos.game.currentTime() - this.gameState.time[0];

		const lyricIndex = this.lyrics.list.findIndex(lyric =>
			isValueBetween(soundCurrentTime, lyric.time[0] - 0.8, lyric.time[1] - 0.8),
		);
		if (lyricIndex >= 0) {
			currentLyric = {...this.lyrics.list[lyricIndex], index: lyricIndex};
		}

		// Lyric changed
		if (currentLyric?.lyrics !== this.lyrics.current?.lyrics) {
			if (this.lyrics.current) {
				// Hide previous lyric
				this.lyrics.$items[this.lyrics.current.index].classList.remove('isCurrent');
			}

			// Set current lyric
			this.lyrics.current = currentLyric;

			// Show current lyric
			this.lyrics.$items[currentLyric.index].classList.add('isCurrent');

			// Set time out to display the lost points if the current lyric hasn't been whacked
			const currentMole = gamePattern[currentLyric.index];
			if (currentMole) {
				setTimeout(() => {
					// Remove some points if the lyric wasn't discovered (get the up to date status from the list of lyrics)
					if (!this.lyrics.list[currentLyric.index].success) {
						// Update points (update total + show lost points)
						this.updatePoints(MISS_MOLE_POINTS, currentMole);
					}
				}, (currentMole.time[1] - this.videos.game.currentTime()) * 1000); // in milliseconds
			}
		}

		// Handle if current lyric has been discovered or not
		if (currentLyric) {
			if (currentLyric.success) {
				this.lyrics.$items[currentLyric.index].classList.add('isSuccess');
			}
		}

		// When song has reached to the end
		if (this.lyrics.current && soundCurrentTime > this.lyrics.list[this.lyrics.list.length - 1].time[1]) {
			// Hide last lyric
			this.lyrics.$items[this.lyrics.current.index].classList.remove('isCurrent');

			// Hide hammer
			this.hideHammer();

			this.lyrics.current = null;
		}
	}

	/**
	 * Update Points
	 * @param {Number} points
	 * @param {object} item (could be a mole or a face data object)
	 */
	updatePoints(points, item) {
		// Update total
		this.updatePointsTotal(points);

		// Get position of the current item to display the points
		const pointsPos = this.transformGameToScreenPositions([
			(item.posX[0] + item.posX[1]) / 2, // Middle X
			item.posY[0], // Top
		]);

		// Show points for the current hole
		this.showHoleScore(points, pointsPos);
	}

	/**
	 * Update Points Total
	 */
	updatePointsTotal(points) {
		this.points.total += points;
	}

	/**
	 * Update Lyric Status (success or not)
	 */
	updateLyricStatus(index, success) {
		this.lyrics.list[index].success = success;
	}

	/**
	 * Update Sound
	 * @param {Array} time ([startTime, endTime] in seconds)
	 */
	unmuteSound(time) {
		// Cancel the need to mute the sound again
		clearTimeout(this.soundsTimeout);

		// Unmute the sound
		this.sounds.game.mute(false);

		// Get current sound time
		const currentTime = this.videos.game.currentTime() - this.gameState.time[0];

		// Mute again once the sound reached the end time of the current lyric
		this.soundsTimeout = setTimeout(() => {
			this.sounds.game.mute(true);
		}, (time[1] - currentTime) * 1000); // Convert to milliseconds
	}

	/**
	 * Setup Screens
	 */
	setupGameScreens() {
		// Loops
		// if (this.gameState.loop) {
		// 	this.startLoop();
		// }
		// Instructions
		if (this.gameState.name === 'instructions') {
			this.buttons.$play.addEventListener('click', this.handleInstructionsBtnClick);

			this.showInstructionsScreen();

			this.startLoop(this.sounds.loopIntro);
		} else {
			this.buttons.$play.removeEventListener('click', this.handleInstructionsBtnClick);
		}
		// Game
		if (this.gameState.name === 'game') {
			if (this.isDesktop) {
				// Detect mouse move to translate hammer
				document.addEventListener('mousemove', this.handleHammerPosition);
			}

			// Play game and client sounds (+ synchronise sounds time with video time to be exactly the same)
			this.sounds.game.currentTime(this.videos.game.currentTime() - this.gameState.time[0]);
			this.sounds.game.play();
			this.sounds.client?.currentTime(this.videos.game.currentTime() - this.gameState.time[0]);
			this.sounds.client?.play();

			// Display hammer (target + image)
			this.showHammer();
		} else {
			if (this.isDesktop) {
				document.removeEventListener('mousemove', this.handleHammerPosition);
			}
		}
		// Score
		if (this.gameState.name === 'score') {
			// GTM Custom Event
			if (window.CLIENT_NAME) {
				window.dataLayer.push({
					event: 'gameFinished',
				});
			}

			this.buttons.$playAgain.addEventListener('click', this.handleScoreBtnClick);

			this.showScoreScreen();

			this.startLoop(this.sounds.loopEnd);
		} else {
			this.buttons.$playAgain.removeEventListener('click', this.handleScoreBtnClick);
		}
	}

	/**
	 * Setup Hammer Animation
	 * @returns {timeline} - hammer whack animation
	 */
	setupHammerAnimation() {
		// Initial position
		gsap.set(this.hammer.$image, {
			xPercent: -15,
			yPercent: 15,
			rotate: 70,
		});

		// whack animation
		const whack = gsap.timeline();
		whack
			// Rotate to hole
			.to(
				this.hammer.$image,
				{
					rotate: 0,
					duration: 0.15,
					ease: 'back.out(2)',
				},
				0,
			)
			// Blink pink/blue
			.set(this.hammer.$pink, {opacity: 1}, 0.05)
			.set(this.hammer.$pink, {opacity: 0}, 0.18)
			.set(this.hammer.$pink, {opacity: 1}, 0.31)
			.set(this.hammer.$pink, {opacity: 0}, 0.44)
			// Rotate back to vertical position
			.to(
				this.hammer.$image,
				{
					rotate: 70,
					duration: 0.2,
					ease: 'power3.in',
				},
				0.34,
			);
		return {whack: whack};
	}

	/**
	 * Setup Lyrics
	 */
	setupLyrics() {
		this.lyrics.list = gamePattern.map(pattern => ({...pattern.music, success: false}));

		// Add client lyric at the end
		this.lyrics.list.push({
			lyrics: window.CLIENT_NAME || '',
			time: [SOUND_CLIENT_START_TIME, this.sounds.game.duration() - 1],
			success: true,
			isClient: true,
		});
		// Add client name in the lyrics HTML
		if (window.CLIENT_NAME) {
			const lyricsClient = document.querySelector(LYRICS_CLIENT_SELECTOR);
			lyricsClient.innerHTML = window.CLIENT_NAME;
			lyricsClient.setAttribute('data-client', window.CLIENT_NAME);
		}
	}

	/**
	 * Show Hole Score
	 * @param {number} points
	 * @param {array} position
	 */
	showHoleScore(points, position) {
		// Create points element
		const $pointsEl = document.createElement('span');
		$pointsEl.className = 'hole-score__points';
		$pointsEl.innerText = points; // Set content
		this.points.$holeScore.appendChild($pointsEl);

		// Init position + color
		gsap.set($pointsEl, {x: position[0], y: position[1], color: points < 0 ? COLOR_RED : COLOR_WHITE});

		// Play animation
		this.animateHoleScore($pointsEl, points < 0);
	}

	/**
	 * Animate Hole Score
	 * @param {HTMLElement} $element
	 * @param {boolean} isError
	 */
	animateHoleScore($element, isError = false) {
		const pointTL = gsap.timeline({
			onComplete: () => {
				$element.remove();
			},
		});

		// Move + scale up
		pointTL.to($element, {
			yPercent: -200,
			scale: 1.5,
			duration: 0.75,
		});

		// Small rotation for error score
		if (isError) {
			pointTL.to(
				$element,
				{
					rotate: -15,
					duration: 0.1,
				},
				'<+=0.1',
			);
			pointTL.to(
				$element,
				{
					rotate: 15,
					duration: 0.1,
				},
				'<+=0.1',
			);
			pointTL.to(
				$element,
				{
					rotate: -10,
					duration: 0.1,
				},
				'<+=0.1',
			);
			pointTL.to(
				$element,
				{
					rotate: 10,
					duration: 0.1,
				},
				'<+=0.1',
			);
			pointTL.to(
				$element,
				{
					rotate: 0,
					duration: 0.1,
				},
				'<+=0.1',
			);
		}

		// Hide
		pointTL.to(
			$element,
			{
				opacity: 0,
				duration: 0.2,
			},
			'-=0.1',
		);
	}

	/**
	 * Show Hammer (target + image)
	 */
	showHammer() {
		if (this.isDesktop) {
			// Show target only for desktop
			gsap.fromTo(
				this.hammer.$target,
				{opacity: 0, scale: 0},
				{opacity: 1, scale: 1, duration: 0.5, delay: 1, ease: 'power2.out'},
			);
		} else {
			// Init hammer position to the right of the window for mobile
			gsap.set(this.hammer.$element, {...this.hammer.mobileInitPos});
		}

		gsap.fromTo(
			this.hammer.$image,
			{opacity: 0, rotate: 90},
			{opacity: 1, rotate: 70, duration: 0.3, delay: 1, ease: 'power2.out'},
		);
	}
	/**
	 * Hide Hammer (target + image)
	 */
	hideHammer() {
		gsap.to(this.hammer.$target, {opacity: 0, scale: 0, duration: 0.4, ease: 'power2.out'});
		gsap.to(this.hammer.$image, {opacity: 0, rotate: 90, duration: 0.2, ease: 'power2.out'});
	}

	/**
	 * Start Loop
	 */
	startLoop(audio) {
		this.videos.game.startLoop(
			this.gameState.loopTime[0],
			this.gameState.loopTime[1],
			audio,
			this.gameState.loopAudioTime[0],
		);
	}

	/**
	 * Stop Loop
	 */
	stopLoop(audio, fade) {
		this.gameState.loop = false;
		this.videos.game.stopLoop(audio, this.gameState.loopTime[1], fade);
	}

	/**
	 * Reset game to initial state
	 * @param {boolean}
	 */
	resetGame() {
		// Reset score points
		this.points.total = 0;

		// Reset lyrics
		this.lyrics.list.forEach((lyric, index) => {
			lyric.success = lyric.isClient ? true : false;

			this.lyrics.$items[index]?.classList.remove('isSuccess');
		});
		this.lyrics.current = null;

		// Stop loops
		if (this.gameState.loop) {
			this.stopLoop(this.sounds.loopEnd);
		}

		// Reset sounds
		Object.keys(this.sounds).forEach(sound => {
			if (this.sounds[sound]) this.sounds[sound].stop();
		});
	}

	/**
	 * Replay Game
	 */
	replayGame() {
		// GTM Custom Event
		if (window.CLIENT_NAME) {
			window.dataLayer.push({
				event: 'gameReplay',
			});
		}

		this.resetGame();

		// Hide Score screen before going to game
		gsap.to(this.$scoreScreen, {
			opacity: 0,
			duration: 0.5,
			onStart: () => {
				// Go to game
				this.goToScreen(2);
			},
		});
	}

	/**
	 * Go To Game Screen
	 * @param {number} screenNumber
	 */
	goToScreen(screenNumber) {
		const screenStartTime = gameScreens[screenNumber].time[0];

		this.videos.game.currentTime(Math.round(screenStartTime * 10) / 10); // round to 1 decimal to fix video going to wrong frame
		this.videos.background.currentTime(Math.round(screenStartTime * 10) / 10);
	}

	/**
	 * UTILITIES
	 */

	/**
	 * Is Click Inside Game
	 * @param {array} clickPosition
	 * @returns {boolean}
	 */
	isClickInsideGame(clickPosition) {
		const currentVideoSpecs = this.videos.game.getCurrentSpecs();

		if (
			isValueBetween(clickPosition[0], currentVideoSpecs.x, currentVideoSpecs.x + currentVideoSpecs.width) &&
			isValueBetween(clickPosition[1], currentVideoSpecs.y, currentVideoSpecs.y + currentVideoSpecs.height)
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Transform Screen To Game Positions
	 * @param {array} position - screen position
	 * @returns {array} game position
	 */
	transformScreenToGamePositions(position) {
		const currentVideoSpecs = this.videos.game.getCurrentSpecs();
		const x = ((position[0] - currentVideoSpecs.x) * originalVideoSpecs.width) / currentVideoSpecs.width;
		const y = ((position[1] - currentVideoSpecs.y) * originalVideoSpecs.height) / currentVideoSpecs.height;

		return [x, y];
	}

	/**
	 * Transform Game To Screen Positions
	 * @param {array} position - game position
	 * @returns {array} screen position
	 */
	transformGameToScreenPositions(position) {
		const currentVideoSpecs = this.videos.game.getCurrentSpecs();
		const x = (position[0] * currentVideoSpecs.width) / originalVideoSpecs.width + currentVideoSpecs.x;
		const y = (position[1] * currentVideoSpecs.height) / originalVideoSpecs.height + currentVideoSpecs.y;

		return [x, y];
	}

	/**
	 * Get Object Clicked
	 * @param {array} objects
	 * @param {number} time - seconds
	 * @param {array} position
	 * @returns {object} - object clicked
	 */
	getObjectClicked(objects, time, position) {
		const objectClicked = objects.find(
			object =>
				isValueBetween(time, object.time[0], object.time[1]) &&
				isValueBetween(position[0], object.posX[0], object.posX[1]) &&
				isValueBetween(position[1], object.posY[0], object.posY[1]),
		);

		// console.log(objectClicked ? objectClicked.name : 'Failed click');
		return objectClicked;
	}

	/**
	 * Blink Animation
	 * @param {HTMLElement} el
	 * @param {string} startColor
	 * @param {string} endColor
	 */
	blink(el, startColor, endColor) {
		const tl = new gsap.timeline({repeat: -1, repeatDelay: 0.6});

		tl.set(el, {color: startColor}).set(el, {color: endColor}, 0.6);
	}
}
