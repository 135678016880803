import videojs from 'video.js';
import {originalVideoSpecs} from './data';
import EventClass from './lib/EventClass';
import {ensurePromise} from './utils';

export default class Video extends EventClass {
	constructor(config) {
		super();
		this.config = config;
		this.$videoJs = undefined;
		this.$videoElement = undefined;
		this.isLooping = false;
		this.loopTimeout = undefined;
	}

	bind() {
		this.createPlayer();
		this.$videoJs = document.querySelector(`#${this.config.selector}`);
		this.$videoElement = document.querySelector(`#${this.config.selector} video`);
	}

	unbind() {
		this.destroyPlayer();
	}

	createPlayer() {
		this.player = videojs(this.config.selector, this.config.options);

		// bind videojs player events
		this.player.on('ready', () => {
			this.emit('ready');
		});
		this.player.on('timeupdate', () => this.emit('timeupdate'));
		this.player.on('play', () => this.emit('play'));
		this.player.on('pause', () => {
			if (!this.forcePause) this.play(); // Disable possibility for the user to pause video (from keyboard for example) => to avoid any bugs between the video and the audios
			this.emit('pause');
		});
		this.player.on('ended', () => this.emit('ended'));
		this.player.on('loadeddata', () => this.emit('loadeddata'));
	}

	destroyPlayer() {
		this?.player.dispose();
	}

	play() {
		return ensurePromise(() => this?.player.play());
	}

	pause() {
		return ensurePromise(() => this?.player.pause());
	}

	stop() {
		return ensurePromise(() => this.pause()).finally(() => this.currentTime(0));
	}

	load() {
		return ensurePromise(() => this?.player.load());
	}

	muted(muted) {
		this?.player.muted(muted);
	}

	/**
	 * Start running loop
	 * @param {number} startTime - in seconds
	 * @param {number} endTime - in seconds
	 * @param {object} audio - Sound
	 * @param {number} audioStartTime - in seconds
	 */
	startLoop(startTime, endTime, audio, audioStartTime) {
		if (this.isLooping) return;
		this.isLooping = true;

		if (audio) {
			setTimeout(() => {
				this.muted(true);

				audio.currentTime();
				audio.play();
			}, (audioStartTime - this.currentTime()) * 1000);
		}

		this.loopTimeout = setTimeout(() => {
			this.currentTime(startTime);
			this.isLooping = false;
			this.startLoop(startTime, endTime);
		}, (endTime - this.currentTime()) * 1000);
	}

	/**
	 * Stop running loop
	 */
	stopLoop(audio, endTime, fade) {
		this.isLooping = false;
		clearTimeout(this.loopTimeout);

		if (audio) {
			if (fade) {
				setTimeout(() => {
					audio.fade(audio.volume(), 0, 1000); // fade the audio
					setTimeout(() => {
						audio.stop();
						this.muted(false);
					}, 1000);
				}, (endTime - this.currentTime()) * 1000); // Stop audio when has done fading
			} else {
				audio.stop();
				this.muted(false);
			}
		}
	}

	/**
	 * Get the video duration
	 * @returns {number} the duration in seconds
	 */
	duration() {
		return this?.player.duration() ?? 0;
	}

	/**
	 * Get or set the current time (in seconds)
	 * @param {number} seconds The time to seek to in seconds
	 */
	currentTime(seconds) {
		if (seconds !== undefined) {
			this?.player.currentTime(`${Math.floor(seconds * originalVideoSpecs.fps) / originalVideoSpecs.fps}`);
			return;
		}
		return Math.floor(this?.player.currentTime() * originalVideoSpecs.fps) / originalVideoSpecs.fps ?? 0;
	}

	/**
	 * Get the video current specs
	 * @returns {object}
	 */
	getCurrentSpecs() {
		return this.$videoJs.getBoundingClientRect();
	}

	/**
	 * Returns the percent (as a decimal) of the video that's been downloaded. 0 means none, 1 means all.
	 * @returns {number}
	 */
	bufferedPercent() {
		return this?.player.bufferedPercent() ?? 0;
	}

	paused() {
		return this?.player.paused();
	}
}
