export const originalVideoSpecs = {
	width: 1440,
	height: 2560,
	fps: 30,
};

export const gameScreens = [
	{
		name: 'intro',
		time: [0, 6.6],
	},
	{
		name: 'instructions',
		time: [6.6, 12.167],
		loopTime: [8.367, 11.267],
		loopAudioTime: [8.3, 11.267],
		loop: true,
	},
	{
		name: 'game',
		time: [12.533, 44.7],
	},
	{
		name: 'score',
		time: [44.8, 55.133],
		// loopTime: [46.825, 52.267],
		loopTime: [49.467 - 0.13, 52.3 - 0.1],
		loopAudioTime: [46.5, 52.267],
		loop: true,
	},
];

export const gameFaces = [
	{
		name: 'face 3',
		person: 'camille',
		posX: [353, 552],
		posY: [1179, 1509],
		time: [18.44, 19.89],
		// time: [18.63, 19.69],
	},
	{
		name: 'face 8',
		person: 'sylvain',
		posX: [291, 499],
		posY: [1618, 1970],
		time: [19.44, 20.92],
		// time: [19.69, 20.66],
	},
	{
		name: 'face 10',
		person: 'charlotte',
		posX: [908, 1147],
		posY: [1600, 1980],
		time: [21.74, 23.15],
		// time: [22.02, 22.92],
	},
	{
		name: 'face 9',
		person: 'julie',
		posX: [600, 799],
		posY: [1629, 1977],
		time: [23.667, 25.033],
		// time: [23.9, 24.8],
	},
	{
		name: 'face 6',
		person: 'felipe',
		posX: [452, 646],
		posY: [1398, 1747],
		time: [24.46, 25.84],
		// time: [24.67, 25.67],
	},
	{
		name: 'face 2',
		person: 'angela',
		posX: [742, 915],
		posY: [989, 1290],
		time: [25.57, 27.07],
		// time: [25.8, 26.86],
	},
	{
		name: 'face 5',
		person: 'fabien',
		posX: [888, 1043],
		posY: [1211, 1515],
		time: [27.9, 29.333],
		// time: [28.1, 29.133],
	},
	{
		name: 'face 6',
		person: 'nadine',
		posX: [436, 633],
		posY: [1413, 1748],
		time: [29.09, 30.58],
		// time: [29.33, 30.33],
	},
	{
		name: 'face 3',
		person: 'pierrick',
		posX: [364, 536],
		posY: [1176, 1509],
		time: [31.167, 32.467],
		// time: [31.333, 32.3],
	},
	{
		name: 'face 10',
		person: 'emilien',
		posX: [901, 1152],
		posY: [1611, 1980],
		time: [33.667, 34.967],
		// time: [33.833, 34.8],
	},
	{
		name: 'face 10',
		person: 'simeon',
		posX: [919, 1135],
		posY: [1603, 1979],
		time: [35.93, 37.24],
		// time: [36.08, 37.09],
	},
	{
		name: 'face 5',
		person: 'hugo',
		posX: [866, 1065],
		posY: [1198, 1515],
		time: [36.7, 38.1],
		// time: [36.9, 37.9],
	},
];

export const gamePattern = [
	{
		name: 'mole 4',
		posX: [640, 806],
		posY: [1288, 1520],
		time: [16.467, 17.767],
		// time: [16.633, 17.64],
		music: {
			lyrics: 'We wish you',
			time: [4.727, 7.278],
		},
	},
	{
		name: 'mole 10',
		posX: [922, 1107],
		posY: [1705, 1980],
		time: [18.467, 19.833],
		// time: [18.7, 19.63],
		music: {
			lyrics: 'a merry',
			time: [7.278, 8.364],
		},
	},
	{
		name: 'mole 2',
		posX: [761, 917],
		posY: [1052, 1289],
		time: [19.467, 20.833],
		// time: [19.667, 20.66],
		music: {
			lyrics: 'Christmas',
			time: [8.364, 10.409],
		},
	},
	{
		name: 'mole 6',
		posX: [445, 618],
		posY: [1493, 1747],
		time: [21.8, 23.067],
		// time: [22, 22.9],
		music: {
			lyrics: 'We wish',
			time: [10.409, 11.968],
		},
	},
	{
		name: 'mole 1',
		posX: [477, 633],
		posY: [1066, 1291],
		time: [23.8, 24.933],
		// time: [23.9, 24.83],
		music: {
			lyrics: 'you',
			time: [11.968, 12.97],
		},
	},
	{
		name: 'mole 5',
		posX: [900, 1064],
		posY: [1276, 1515],
		time: [24.467, 25.833],
		// time: [24.667, 25.66],
		music: {
			lyrics: 'a merry',
			time: [12.97, 14.064],
		},
	},
	{
		name: 'mole 8',
		posX: [325, 508],
		posY: [1704, 1970],
		time: [25.633, 27],
		// time: [25.867, 26.82],
		music: {
			lyrics: 'Christmas',
			time: [14.064, 16.009],
		},
	},
	{
		name: 'mole 3',
		posX: [350, 513],
		posY: [1270, 1510],
		time: [27.9, 29.167],
		// time: [28.067, 29],
		music: {
			lyrics: 'We wish',
			time: [16.009, 17.572],
		},
	},
	{
		name: 'mole 7',
		posX: [751, 922],
		posY: [1493, 1743],
		time: [29.167, 30.467],
		// time: [29.367, 30.32],
		music: {
			lyrics: 'you',
			time: [17.572, 18.598],
		},
	},
	{
		name: 'mole 2',
		posX: [761, 917],
		posY: [1052, 1289],
		time: [30.567, 31.9],
		// time: [30.733, 31.76],
		music: {
			lyrics: 'a merry',
			time: [18.598, 19.692],
		},
	},
	{
		name: 'mole 10',
		posX: [922, 1107],
		posY: [1705, 1980],
		time: [31.133, 32.467],
		// time: [31.367, 32.3],
		music: {
			lyrics: 'Christmas',
			time: [19.692, 21.742],
		},
	},
	{
		name: 'mole 9',
		posX: [633, 816],
		posY: [1694, 1977],
		time: [33.667, 34.967],
		// time: [33.867, 34.83],
		music: {
			lyrics: 'And a Ha',
			time: [21.742, 23.172],
		},
	},
	{
		name: 'mole 4',
		posX: [640, 806],
		posY: [1288, 1520],
		time: [34.467, 35.667],
		// time: [34.6, 35.56],
		music: {
			lyrics: 'ppy',
			time: [23.172, 24.478],
		},
	},
	{
		name: 'mole 6',
		posX: [445, 618],
		posY: [1493, 1747],
		time: [35.9, 37.233],
		// time: [36.1, 37.06],
		music: {
			lyrics: 'new',
			time: [24.478, 25.292],
		},
	},
	{
		name: 'mole 3',
		posX: [350, 513],
		posY: [1270, 1510],
		time: [36.767, 38.067],
		// time: [36.933, 37.89],
		music: {
			lyrics: 'year',
			time: [25.292, 27.2],
		},
	},
];

// export const gameButtons = [
// 	{
// 		name: 'play',
// 		time: [7.633, 11.3],
// 		posX: [577, 867],
// 		posY: [1979, 2104],
// 		width: 290,
// 		height: 125,
// 	},
// 	{
// 		name: 'playagain',
// 		time: [45.433, 52.333],
// 		posX: [468, 972],
// 		posY: [1655, 1775],
// 		width: 504,
// 		height: 120,
// 	},
// 	{
// 		name: 'linkedin',
// 		time: [45.433, 52.333],
// 		posX: [628, 708],
// 		posY: [2143, 2223],
// 		width: 80,
// 		height: 80,
// 	},
// 	{
// 		name: 'instagram',
// 		time: [45.433, 52.333],
// 		posX: [722, 802],
// 		posY: [2143, 2223],
// 		width: 80,
// 		height: 80,
// 	},
// ];
